import React, { useEffect, useRef } from 'react';
import Arrow from '@assets/svg/arrow.svg';
import Dot from '@assets/svg/dot.svg';

import s from './TypingListHelper.module.scss';


interface ITypingListHelper {
  isValueWrong: boolean;
  visible: boolean;
  handleClick(visibility: boolean, fieldIndex?: number): void;
  handleSelect(optionIndex?: string, fieldIndex?: number, index?: number): void;
  handleChangeCountry(e:any): void;
  range: string[];
  value: string;
  placeholder: string;
  required?: boolean;
  fieldIndex?: number;
}


const TypingListHelper = ({isValueWrong, visible, handleClick, range, value, placeholder, handleSelect, required, handleChangeCountry}: ITypingListHelper) => {
  
  const refTypingListField = useRef<HTMLDivElement>(null);
  const refInputField = useRef<HTMLInputElement>(null);

  const serviceElementFocus = (borderColor: string) => {
    if (refTypingListField.current) {
      refTypingListField.current.style.borderColor = borderColor;
    }
  };

  useEffect(() => {
    const addFocusToElement = () => serviceElementFocus('black');
    const addFocusOutToElement = () => serviceElementFocus('white');

    refInputField.current?.addEventListener('focus', addFocusToElement);
    refInputField.current?.addEventListener('blur', addFocusOutToElement);

    return () => {
      refInputField.current?.removeEventListener('focus', addFocusToElement);
      refInputField.current?.removeEventListener('blur', addFocusOutToElement);
    };
  }, [refInputField.current]);
  
  
  return (
    <div className={s.typingListHelper}>
      <div className={`${s.typingListHelper__field} ${isValueWrong ? s.red : ''}`}
           ref={refTypingListField}
           onClick={ () => handleClick(!visible) }>
        <p className={`${s.typingListHelper__inputLabel} ${value ? s.full : ''}`}>{placeholder}</p>
        <input className={`${s.typingListHelper__input} ${value ? s.full : ''} ${isValueWrong ? s.red : ''}`}
               onChange={(e) => handleChangeCountry(e)}
               ref={refInputField}
               type={'text'}
               autoComplete={'new-country'}
               value={value} placeholder={placeholder} required={required} />
        <div className={`${s.typingListHelper__arrow} ${value ? s.selected : ''}`}>
          <Arrow className={s.typingListHelper__arrowSvg} />
        </div>
      </div>
      <div className={`${s.typingListHelper__dropdown} ${visible ? s.visible : ''}`}>
        {
          range.map((option, index) => {
            return (
              <li className={s.typingListHelper__option} key={index}
                  onClick={() => handleSelect(option)}>
                <p>{option}</p>
                <div className={`${s.typingListHelper__iconRing} ${option === value ? s.selected : ''}`}>
                  <div className={`${s.typingListHelper__icon} ${option === value ? 'selected' : ''}`}>
                    {<Dot className={s.typingListHelper__iconSvg} />}
                  </div>
                </div>
              </li>
            );
          })
        }
      </div>
    </div>
  );
}

export default TypingListHelper;
