import React, { FC, useRef } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { GuaranteeForm } from '@components/guarantee-form/GuaranteeForm.component';
import { Container } from '@components/container/Container.component';

import { AllWpGuaranteeProducts, ListedGuaranteeNode } from '../../types';
import { getDatesInRange, guaranteeDayNumber } from '@helpers/date.helper';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import Thumbnail from '@assets/thumbnails/warranty.jpg';

import s from './Guarantee.module.scss';


interface IGuaranteeTemplate {
  data: {
    allWpGuaranteeProduct: AllWpGuaranteeProducts;
    allWpGuarantee: ListedGuaranteeNode
  }
  location: {
    state: {
      currentProduct: string;
    }
  }
}

const GuaranteeTemplate: FC<IGuaranteeTemplate> = (props) => {
  const {
    data: {
      allWpGuaranteeProduct,
      wpGuarantee,
    },
    location: {
      state,
    },
  } = props;

  const intl = useIntl();

  const edges = [...allWpGuaranteeProduct.edges];

  const guaranteeGraphic = wpGuarantee.guaranteeData;

  const prepareGuaranteeProductList = () => {
    const guaranteeProductList = edges.map((product) => {
      return product.node.guaranteeProductGroup.product && product.node.guaranteeProductGroup.product[0].productMetaData.productname;
    });

    return guaranteeProductList;
  };

  const prepareDateRange = () => {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setDate(maxDate.getDate() - guaranteeDayNumber);

    return getDatesInRange(maxDate, today);
  };

  const refGuarantee = useRef<HTMLInputElement>(null);

  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.WARRANTY)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.WARRANTY)}/`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.WARRANTY)}/`}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__warranty' })}`}
        description={intl.formatMessage({ id: 'metaDescription__warranty' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'metaTitle__warranty' }),
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__warranty' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__warranty' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__warranty' }),
          },
        ]}
      />
      <Container>
        <div className={s.guarantee} ref={refGuarantee}>
          <div className={s.guarantee__content}>
            <div className={s.guarantee__data}>
              <div className={s.guarantee__photo}>
                <GatsbyImage
                  className={s.guarantee__img}
                  image={getImage(guaranteeGraphic.graphic.localFile)!}
                  alt={guaranteeGraphic.graphic.altText}
                />
              </div>
            </div>
            <div className={s.guarantee__form}>
              <GuaranteeForm
                header={intl.formatMessage({ id: 'guarantee__formHeader' })}
                subtitle={intl.formatMessage({ id: 'guarantee__formSubtitle' })}
                namePlaceholder={intl.formatMessage({ id: 'guarantee__formName' })}
                emailPlaceholder={intl.formatMessage({ id: 'guarantee__formEmail' })}
                phonePlaceholder={intl.formatMessage({ id: 'guarantee__formPhone' })}
                serialNumberPlaceholder={intl.formatMessage({ id: 'guarantee__formSerialNumber' })}
                dateOfPurchasePlaceholder={intl.formatMessage({ id: 'guarantee__formDateOfPurchase' })}
                productPlaceholder={intl.formatMessage({ id: 'guarantee__formProduct' })}
                childBirthDatePlaceholder={intl.formatMessage({ id: 'guarantee__formChildBirthDate' })}
                datepickerLabel={intl.formatMessage({ id: 'guarantee__datepickerLabel' })}
                countryNameLabel={intl.formatMessage({ id: 'guarantee__formCountryName' })}
                codeOfResidencePlaceholder={intl.formatMessage({ id: 'guarantee__formCodeOfResidence' })}
                shopNamePlaceholder={intl.formatMessage({ id: 'guarantee__formShopName' })}
                shopPlaceNamePlaceholder={intl.formatMessage({ id: 'guarantee__formNameOfShopPlace' })}
                checkboxStationaryShopping={intl.formatMessage({ id: 'guarantee__formShoppingStationary' })}
                receiptNumberPlaceholder={intl.formatMessage({ id: 'guarantee__formReceiptNumber' })}
                checkboxLabelPart1={intl.formatMessage({ id: 'guarantee__formCheckboxLabel' })}
                checkboxLabelPart2={intl.formatMessage({ id: 'guarantee__formCheckboxLabelGDPR' })}
                checkboxLabelTwo={intl.formatMessage({ id: 'guarantee__formCheckboxTwo' })}
                checkboxLabelThree={intl.formatMessage({ id: 'guarantee__formCheckboxThree' })}
                checkboxLabelFour={intl.formatMessage({ id: 'guarantee__formCheckboxFour' })}
                checkboxLabelFive={intl.formatMessage({ id: 'guarantee__formCheckboxFive' })}
                checkboxLabelSix={intl.formatMessage({ id: 'guarantee__formCheckboxSix' })}
                onlineShoppingLabel={intl.formatMessage({ id: 'guarantee__formOnlineShopping' })}
                guaranteeProductList={prepareGuaranteeProductList()}
                dateRange={prepareDateRange()}
                outsideRef={refGuarantee}
                currentProduct={state?.currentProduct}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GuaranteeTemplate;

export const query = graphql`
  query GetGuaranteeProductList($locale: String) {
    allWpGuaranteeProduct (
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          guaranteeProductGroup {
            product {
              ... on WpProduct {
                productMetaData {
                  productname
                }
              }
            }
          }
        }
      }
    }
    wpGuarantee(slug: {eq: "gwarancja"}, locale: {locale: {eq: $locale}}) {
      id
      locale {
        locale
      }
      guaranteeData {
        graphic {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100 )
            }
          }
        }
        textGraphic
      }
      slug
    }
  }
`;
