import React from 'react';
import { gsap } from 'gsap';
import { navigate } from 'gatsby';

import { getFormatDate } from '@helpers/date.helper';
import { FetchResponse } from '../../types';
import { getLink } from '@helpers/linking.helper';
import { additionalProductsIncludesExtendedWarranty } from '@helpers/guarantee.helper';
import { CountriesDictionaries, europeanCountryInPolish } from '@helpers/countryInPolish.helper';

import { PageType } from '@constants/pageType.constants';
import { CustomSelect } from '../custom-select/CustomSelect.component';
import { Input } from '../input/Input.component';

import Tooltip from '@assets/svg/tooltip.svg';
import SerialNumberTooltip from '@assets/images/tooltip.jpg';
import Trash from '@assets/svg/trash.svg';

import s from './GuaranteeForm.module.scss';

const MAX_NAME_LENGTH = 30;
const PRODUCT_LIMIT = 7;
const DUPLICATED_SN_CODE = 464;
const INCOMPLETE_CODE = 230;

enum marketingConsents {
  ConsentClientData = 0,
  ConsentChildData = 1,
  ConsentCommunicationPhone = 2,
  ConsentCommunicationMail = 3,
  ConsentCommunicationSMS = 4,
}

enum Endpoint {
  Dev = 'https://vpcme0apgg.execute-api.eu-west-1.amazonaws.com/DEV/register',
  Prod = 'https://jq6ra42jn1.execute-api.eu-west-1.amazonaws.com/PROD/register',
  Unlimited = 'https://qwmzcw66ok.execute-api.eu-west-1.amazonaws.com/UNLIMITED/register'
}

export const AcceptableFileFormats: string[] = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const handleSetCheckboxSelected = (index: number, selected: boolean[], setCheckboxSelected: (selected: boolean) => void) => {
  const newState = [...selected];

  newState[index] = !newState[index];

  setCheckboxSelected(newState);
};

export const renderCheckbox = (selected: boolean[], checboxLabels: string[], setCheckboxSelected: (selected: boolean) => void) => {

  return selected.map((checkbox, index) => {
    return (
      <div className={s.guaranteeForm__checkbox}
           onClick={() => handleSetCheckboxSelected(index, selected, setCheckboxSelected)} key={checboxLabels[index]}>
        <input className={`${s.guaranteeForm__checkboxInput} ${selected[index] ? s.selected : ''}`} type="checkbox"
               checked={selected[index]} onChange={() => {
        }}/>
        <label className={s.guaranteeForm__checkboxLabel}>
          <p>
            {checboxLabels[index]}
          </p>
        </label>
      </div>
    );
  });
};

export const open = (setCheckboxSelectedShoppingStationary: (selected: boolean) => void, refShopPlaceName, setIsShopPlaceNameVisible: (visible: boolean) => void) => {
  let tl = gsap.timeline();

  setCheckboxSelectedShoppingStationary(true);

  if (refShopPlaceName.current !== null) {
    tl.kill();
    tl = gsap.timeline();
    tl.to(refShopPlaceName.current, {
      autoAlpha: 1,
      height: 80,
      duration: .1,
      ease: 'power3.in',
      onComplete: () => setIsShopPlaceNameVisible(true),
    });
  }
};

export const hide = (setCheckboxSelectedShoppingStationary: (selected: boolean) => void, refShopPlaceName, setIsShopPlaceNameVisible: (visible: boolean) => void) => {
  let tl = gsap.timeline();

  setCheckboxSelectedShoppingStationary(false);

  if (refShopPlaceName.current !== null) {
    tl.kill();
    tl = gsap.timeline();
    tl.to(refShopPlaceName.current, {
      autoAlpha: 0,
      height: 0,
      duration: .2,
      ease: 'power3.out',
      onComplete: () => setIsShopPlaceNameVisible(false),
    });
  }
};

export const closeAllDropdowns = (
  setDisplayDropdownProducts: (optionalIndex: boolean[]) => void,
  setDisplayDropdownDateOfPurchase: (value: boolean) => void,
  setDisplayDropdownCountryName: (value: boolean) => void,
) => {

  setDisplayDropdownProducts([false, false, false, false, false, false, false, false]);
  setDisplayDropdownDateOfPurchase(false);
  setDisplayDropdownCountryName(false);
};

export const rollFileName = (name: string) => {
  if (name.length > MAX_NAME_LENGTH) {
    return name.substring(0, MAX_NAME_LENGTH - 1) + '...';
  }

  return name;
};

const prepareProductsList = (selectedProducts: boolean[], data: object) => {
  const products = [];

  selectedProducts.map((selectedProduct, index) => {
    const valuePair = {
      serialNumber: data.get(`serial-number-${index}`) as string,
      carSeatName: selectedProduct,
    };

    products.push(valuePair);
  });

  return products;
};

export const handleFormSubmit = (
  event: object,
  setIsLoading: (loading: boolean) => void,
  selectedFileIsTooBig: boolean,
  selectedFileHasWrongFormat: boolean,
  selectedCountryName: string,
  selectedDateOfPurchase: string,
  selectedProducts: string[],
  selectedChildBirthDate: object,
  selectedPhoto: any,
  isShopPlaceNameVisible: boolean,
  locale: string,
  onlineShoppingLabel: string,
  selected: boolean[],
  handleSetIsSelectedCountryNameWrong: any,
  selectedAnswerToTheQuestionHowDidYouFind: string,
  selectedAnswerToTheQuestionWhatPromptedYou: string
) => {

  event.preventDefault();

  if (selectedCountryName.length === 0) {
    handleSetIsSelectedCountryNameWrong();
    return;
  }

  setIsLoading(true);
  let form = [];
  let data = [];

  if (selectedFileIsTooBig || selectedFileHasWrongFormat) {
    return;
  }

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    form = document.getElementById('guarantee-form')!;
    data = new FormData(form);
  }

  const payload = {
    name: data.get('name') as string,
    email: data.get('email') as string,
    phone: data.get('phone') as string,
    serialNumber: data.get('serial-number') as string,
    shopName: data.get('shop-name') as string,
    codeOfResidence: data.get('code-of-residence') as string,
    howDidYouFindOutAboutOurProducts: data.get('answer-to-the-question-howDidYouFind') as string,
    whatPromptedYouToMakePurchase: data.get('answer-to-the-question-whatPromptedYou') as string,
  };

  const products = prepareProductsList(selectedProducts, data);

  const adjustedDictionary = CountriesDictionaries[locale];

  let payloadData = {
    name: payload.name,
    email: payload.email,
    phone: payload.phone,
    purchaseDate: selectedDateOfPurchase,
    birthDate: getFormatDate(selectedChildBirthDate),
    storeName: payload.shopName,
    countryOfPurchase: locale === 'pl' ? selectedCountryName : adjustedDictionary[selectedCountryName],
    postCode: payload.codeOfResidence,
    locale,
    receipt: selectedPhoto,
    products,
    consentClientData: selected[marketingConsents.ConsentClientData],
    consentChildData: selected[marketingConsents.ConsentChildData],
    consentCommunicationPhone: selected[marketingConsents.ConsentCommunicationPhone],
    consentCommunicationMail: selected[marketingConsents.ConsentCommunicationMail],
    consentCommunicationSMS: selected[marketingConsents.ConsentCommunicationSMS],
    isWithMail: true,
    isPurchasedOnline: !isShopPlaceNameVisible,
    howDidYouFindOutAboutOurProducts: payload.howDidYouFindOutAboutOurProducts === null ? selectedAnswerToTheQuestionHowDidYouFind : payload.howDidYouFindOutAboutOurProducts,
    whatPromptedYouToMakePurchase: payload.whatPromptedYouToMakePurchase === null ? selectedAnswerToTheQuestionWhatPromptedYou : payload.whatPromptedYouToMakePurchase,
  };

  if (isShopPlaceNameVisible) {
    payloadData = {
      ...payloadData,
      purchaseCity: data.get('shop-place-name') as string,
    };
  } else {
    payloadData = {
      ...payloadData,
      purchaseCity: onlineShoppingLabel,
    };
  }
  
  const content = JSON.stringify(payloadData);
  
  registerProducts(content, locale, setIsLoading);
};

const registerProducts = async (content: string, locale: string, setIsLoading: (loading: boolean) => void) => {
  const registrationEndpoint = Endpoint.Prod;

  const responseData: FetchResponse = {
    code: null,
    isOk: false,
  };

  await fetch(registrationEndpoint, {
    method: 'POST',
    body: content,
    headers: { 'Content-Type': 'application/json' },
  }).then(response => {
    responseData.code = response.status;
    responseData.isOk = response.ok;
    return response.json();
  })
    .then(data => {
      if (responseData.code === INCOMPLETE_CODE) {
        navigate(
          getLink(locale, PageType.REGISTRATION_INCOMPLETE),
          {
            state: {
              email: data.mail,
              link: data.certificateUrl,
            },
          },
        );
      } else if (responseData.code === DUPLICATED_SN_CODE) {
        navigate(
          getLink(locale, PageType.REGISTRATION_PROBLEM),
          {
            state: {
              isSNDuplicated: true,
            },
          },
        );
      } else if (responseData.isOk) {
        navigate(
          getLink(locale, PageType.REGISTRATION_COMPLETE),
          {
            state: {
              email: data.mail,
              link: data.certificateUrl,
            },
          },
        );
      } else {
        navigate(
          getLink(locale, PageType.REGISTRATION_PROBLEM),
        );
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

const removeProduct = (
  setSelectedSerialNumbers: (value: string[]) => void,
  selectedSerialNumbers: string[],
  index: number,
  selectedProducts: string[],
  setSelectedProducts: (newState: string[]) => void,
  setRenderedProductsNumber: (productNumber: number) => void,
  renderedProductsNumber: number,
  setIsTooMuchAddedProducts: (addButton: boolean) => void,
) => {
  const newStateProducts = [...selectedProducts];
  const newStateSerialNumber = [...selectedSerialNumbers];

  newStateProducts.splice(index, 1);
  newStateSerialNumber.splice(index, 1);

  setSelectedProducts(newStateProducts);
  setSelectedSerialNumbers(newStateSerialNumber);
  setRenderedProductsNumber(renderedProductsNumber - 1);
  setIsTooMuchAddedProducts(false);
};

const handleSetSelectedSerialNumbers = (sn, index, setSerialNumber, reference) => {
  setSerialNumber && setSerialNumber(sn, index, reference);
};

export const renderRepeatableField = (
  snRefs: string[],
  selectedSerialNumbers: string[],
  handleSetSerialNumber: () => void,
  setSelectedSerialNumbers: (value: string[]) => void,
  renderedProductsNumber: number,
  productPlaceholder: string,
  guaranteeProductListFromCMS: string[],
  displayDropdownProducts: boolean[],
  handleSetDisplayDropdownProducts: () => void,
  selectedProducts: string[],
  setProduct: () => void,
  serialNumberPlaceholder: string,
  setSelectedProducts: () => void,
  setRenderedProductsNumber: () => void,
  setIsTooMuchAddedProducts: () => void,
  tooltip: string) => {

  const fields = [];

  const warrantyProductList = [...guaranteeProductListFromCMS, ...additionalProductsIncludesExtendedWarranty];

  for (let index = 0; index <= renderedProductsNumber; index++) {
    fields.push(
      <div className={`${s.guaranteeForm__grid} ${index === 0 ? s.isDeleteButtonHide : ''}`}
           key={`${selectedProducts[index]}-${index}`}>
        <div className={`${s.guaranteeForm__field} ${s.guaranteeForm__product}`}>
          <CustomSelect
            placeholder={productPlaceholder}
            range={warrantyProductList}
            visible={displayDropdownProducts[index]}
            handleClick={handleSetDisplayDropdownProducts}
            value={selectedProducts[index]}
            handleSelect={setProduct}
            isGuaranteeForm
            required
            fieldIndex={index}
          />
        </div>
        <div className={s.guaranteeForm__serialNumber}>
          <Input placeholder={serialNumberPlaceholder}
                 name={`serial-number-${index}`}
                 isFull={selectedSerialNumbers[index]}
                 type={'text'}
                 reference={snRefs[index]}
                 value={selectedSerialNumbers[index]}
                 index={index}
                 setSerialNumber={handleSetSerialNumber}
                 changeMethod={handleSetSelectedSerialNumbers}
                 isRepeatField
                 required
          />
          <div className={s.guaranteeForm__tooltip}>
            <Tooltip className={s.guaranteeForm__tooltipSvg}/>
            <div className={s.guaranteeForm__tooltipMessage}>
              <div className={s.guaranteeForm__tooltipTriangle}/>
              <img src={SerialNumberTooltip} className={s.guaranteeForm__image}/>
              <p className={s.guaranteeForm__tooltipText}>{tooltip}</p>
            </div>
          </div>
        </div>
        <div className={`${s.guaranteeForm__trash} ${index === 0 ? s.isDeleteButtonHide : ''}`}
             onClick={() => removeProduct(setSelectedSerialNumbers, selectedSerialNumbers, index, selectedProducts, setSelectedProducts, setRenderedProductsNumber, renderedProductsNumber, setIsTooMuchAddedProducts)}>
          <Trash className={s.guaranteeForm__trashSvg}/>
        </div>
      </div>,
    );
  }

  return fields;
};

export const addAnotherProduct = (renderedProductsNumber: number, setRenderedProductsNumber: (productNumber: number) => void, setIsTooMuchAddedProducts: (addButton: boolean) => void) => {
  if (renderedProductsNumber + 1 <= PRODUCT_LIMIT) {
    setRenderedProductsNumber(renderedProductsNumber + 1);
  }

  if (renderedProductsNumber + 1 === PRODUCT_LIMIT) {
    setIsTooMuchAddedProducts(true);
  }
};

export const renderQuestionField = (
  handleSelectAnswer: () => void,
  selectedAnswer: string,
  isChoosingOtherOption: boolean,
  setTypedOtherAnswer: any,
  typedOtherAnswer: any,
  selectPlaceholder: string,
  inputPlaceholder: string,
  answerList: string[],
  handleSetDisplayDropdownProducts: () => void,
  displayDropdownProducts: boolean[],
  numberOfQuestion: number,
  questionId: string,
) => {
  
  return (
    <>
      <div>
        <CustomSelect
          placeholder={selectPlaceholder}
          range={answerList}
          visible={displayDropdownProducts[numberOfQuestion]}
          handleClick={handleSetDisplayDropdownProducts}
          value={selectedAnswer}
          handleSelect={handleSelectAnswer}
          isGuaranteeForm
          required={!isChoosingOtherOption}
          fieldIndex={numberOfQuestion}
        />
      </div>
      {isChoosingOtherOption &&
        <div className={s.guaranteeForm__checkField}>
          <Input placeholder={inputPlaceholder}
                 name={`answer-to-the-question-${questionId}`}
                 type={'text'}
                 isFull={typedOtherAnswer}
                 value={typedOtherAnswer}
                 index={numberOfQuestion}
                 changeMethod={setTypedOtherAnswer}
                 required
                 onlyTopPadding
          />
        </div>
      }
    </>
  );
};

