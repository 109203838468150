export const europeanCountryInPolish = [
  'Albania',
  'Andora',
  'Armenia',
  'Austria',
  'Azerbejdżan',
  'Białoruś',
  'Belgia',
  'Bośnia i Hercegowina',
  'Bułgaria',
  'Chorwacja',
  'Cypr',
  'Czechy',
  'Dania',
  'Estonia',
  'Finlandia',
  'Francja',
  'Gruzja',
  'Niemcy',
  'Grecja',
  'Węgry',
  'Islandia',
  'Irlandia',
  'Włochy',
  'Kazachstan',
  'Łotwa',
  'Lichtenstein',
  'Litwa',
  'Luxemburg',
  'Macedonia',
  'Malta',
  'Mołdawia',
  'Monako',
  'Czarnogóra',
  'Holandia',
  'Norwegia',
  'Polska',
  'Portugalia',
  'Rumunia',
  'Rosja',
  'San Marino',
  'Serbia',
  'Słowacja',
  'Hiszpania',
  'Szwecja',
  'Szwajcaria',
  'Turcja',
  'Ukraina',
  'Wielka Brytania',
];


export const EnglishToPolishCountry = {
  'Albania': 'Albania',
  'Andorra': 'Andora',
  'Armenia': 'Armenia',
  'Austria': 'Austria',
  'Azerbaijan': 'Azerbejdżan',
  'Belarus': 'Białoruś',
  'Belgium': 'Belgia',
  'Bosnia and Herzegovina': 'Bośnia i Hercegowina',
  'Bulgaria': 'Bułgaria',
  'Croatia': 'Chorwacja',
  'Cyprus': 'Cypr',
  'Czech Republic': 'Czechy',
  'Denmark': 'Dania',
  'Estonia': 'Estonia',
  'Finland': 'Finlandia',
  'France': 'Francja',
  'Georgia': 'Gruzja',
  'Germany': 'Niemcy',
  'Greece': 'Grecja',
  'Hungary': 'Węgry',
  'Iceland': 'Islandia',
  'Ireland': 'Irlandia',
  'Italy': 'Włochy',
  'Kazakhstan': 'Kazachstan',
  'Latvia': 'Łotwa',
  'Liechtenstein': 'Lichtenstein',
  'Lithuania': 'Litwa',
  'Luxembourg': 'Luxemburg',
  'Macedonia': 'Macedonia',
  'Malta': 'Malta',
  'Moldova': 'Mołdawia',
  'Monaco': 'Monako',
  'Montenegro': 'Czarnogóra',
  'Netherlands': 'Holandia',
  'Norway': 'Norwegia',
  'Poland': 'Polska',
  'Portugal': 'Portugalia',
  'Romania': 'Rumunia',
  'Russia': 'Rosja',
  'San Marino': 'San Marino',
  'Serbia': 'Serbia',
  'Slovakia': 'Słowacja',
  'Spain': 'Hiszpania',
  'Sweden': 'Szwecja',
  'Switzerland': 'Szwajcaria',
  'Turkey': 'Turcja',
  'Ukraine': 'Ukraina',
  'United Kingdom': 'Wielka Brytania',
  'Vatican': 'Watykan',
};

export const GermanToPolishCountry = {
  'Albanien': 'Albania',
  'Andorra': 'Andora',
  'Armenien': 'Armenia',
  'Österreich': 'Austria',
  'Aserbaidschan': 'Azerbejdżan',
  'Weißrussland': 'Białoruś',
  'Belgien': 'Belgia',
  'Bosnien und Herzegowina': 'Bośnia i Hercegowina',
  'Bulgarien': 'Bułgaria',
  'Kroatien': 'Chorwacja',
  'Zypern': 'Cypr',
  'Tschechische Republik': 'Czechy',
  'Dänemark': 'Dania',
  'Estland': 'Estonia',
  'Finnland': 'Finlandia',
  'Frankreich': 'Francja',
  'Georgien': 'Gruzja',
  'Deutschland': 'Niemcy',
  'Griechenland': 'Grecja',
  'Ungarn': 'Węgry',
  'Island': 'Islandia',
  'Irland': 'Irlandia',
  'Italien': 'Włochy',
  'Kasachstan': 'Kazachstan',
  'Lettland': 'Łotwa',
  'Liechtenstein': 'Lichtenstein',
  'Litauen': 'Litwa',
  'Luxemburg': 'Luxemburg',
  'Mazedonien': 'Macedonia',
  'Malta': 'Malta',
  'Moldavien': 'Mołdawia',
  'Monaco': 'Monako',
  'Montenegro': 'Czarnogóra',
  'Niederlande': 'Holandia',
  'Norwegen': 'Norwegia',
  'Polen': 'Polska',
  'Portugal': 'Portugalia',
  'Rumänien': 'Rumunia',
  'Russland': 'Rosja',
  'San Marino': 'San Marino',
  'Serbien': 'Serbia',
  'Slowakei': 'Słowacja',
  'Spanien': 'Hiszpania',
  'Schweden': 'Szwecja',
  'Schweiz': 'Szwajcaria',
  'Türkei': 'Turcja',
  'Ukraine': 'Ukraina',
  'Großbritannien': 'Wielka Brytania',
  'Vatikanstadt': 'Watykan',
};

export const FranceToPolishCountry = {
  'Albanie': 'Albania',
  'Andorre': 'Andora',
  'Arménie': 'Armenia',
  'Autriche': 'Austria',
  'Azerbaïdjan': 'Azerbejdżan',
  'Biélorussie': 'Białoruś',
  'Belgique': 'Belgia',
  'Bosnie-Herzégovine': 'Bośnia i Hercegowina',
  'Bulgarie': 'Bułgaria',
  'Croatie': 'Chorwacja',
  'Chypre': 'Cypr',
  'Tchéquie': 'Czechy',
  'Danemark': 'Dania',
  'Estonie': 'Estonia',
  'Finlande': 'Finlandia',
  'France': 'Francja',
  'Géorgie': 'Gruzja',
  'Allemagne': 'Niemcy',
  'Grèce': 'Grecja',
  'Hongrie': 'Węgry',
  'Islande': 'Islandia',
  'Irlande': 'Irlandia',
  'Italie': 'Włochy',
  'Kazakhstan': 'Kazachstan',
  'Lettonie': 'Łotwa',
  'Liechtenstein': 'Lichtenstein',
  'Lituanie': 'Litwa',
  'Luxembourg': 'Luxemburg',
  'Macédoine': 'Macedonia',
  'Malte': 'Malta',
  'Moldavie': 'Mołdawia',
  'Monaco': 'Monako',
  'Monténégro': 'Czarnogóra',
  'Pays-Bas': 'Holandia',
  'Norvège': 'Norwegia',
  'Pologne': 'Polska',
  'Portugal': 'Portugalia',
  'Roumanie': 'Rumunia',
  'Russie': 'Rosja',
  'Saint-Marin': 'San Marino',
  'Serbie': 'Serbia',
  'Slovaquie': 'Słowacja',
  'Espagne': 'Hiszpania',
  'Suède': 'Szwecja',
  'Suisse': 'Szwajcaria',
  'Turquie': 'Turcja',
  'Ukraine': 'Ukraina',
  'Royaume-Uni': 'Wielka Brytania',
  'Vatican': 'Watykan',
};

export const SpainToPolishCountry = {
  'Albania': 'Albania',
  'Andorra': 'Andora',
  'Armenia': 'Armenia',
  'Austria': 'Austria',
  'Azerbaiyán': 'Azerbejdżan',
  'Bielorrusia': 'Białoruś',
  'Bélgica': 'Belgia',
  'Bosnia y Herzegovina': 'Bośnia i Hercegowina',
  'Bulgaria': 'Bułgaria',
  'Croacia': 'Chorwacja',
  'Chipre': 'Cypr',
  'República Checa': 'Czechy',
  'Dinamarca': 'Dania',
  'Estonia': 'Estonia',
  'Finlandia': 'Finlandia',
  'Francia': 'Francja',
  'Georgia': 'Gruzja',
  'Alemania': 'Niemcy',
  'Grecia': 'Grecja',
  'Hungría': 'Węgry',
  'Islandia': 'Islandia',
  'Irlanda': 'Irlandia',
  'Italia': 'Włochy',
  'Kazajistán': 'Kazachstan',
  'Letonia': 'Łotwa',
  'Liechtenstein': 'Lichtenstein',
  'Lituania': 'Litwa',
  'Luxemburgo': 'Luxemburg',
  'Macedonia': 'Macedonia',
  'Malta': 'Malta',
  'Moldavia': 'Mołdawia',
  'Mónaco': 'Monako',
  'Montenegro': 'Czarnogóra',
  'Holanda': 'Holandia',
  'Noruega': 'Norwegia',
  'Polonia': 'Polska',
  'Portugal': 'Portugalia',
  'Rumanía': 'Rumunia',
  'Rusia': 'Rosja',
  'San Marino': 'San Marino',
  'Serbia': 'Serbia',
  'Eslovaquia': 'Słowacja',
  'España': 'Hiszpania',
  'Suecia': 'Szwecja',
  'Suiza': 'Szwajcaria',
  'Turquía': 'Turcja',
  'Ucrania': 'Ukraina',
  'Gran Bretaña': 'Wielka Brytania',
  'Vaticano': 'Watykan',
};

export const CroatiaToPolishCountry = {
  'Albanija': 'Albania',
  'Andora': 'Andora',
  'Armenija': 'Armenia',
  'Austrija': 'Austria',
  'Azerbajdžan': 'Azerbejdżan',
  'Bjelorusija': 'Białoruś',
  'Belgija': 'Belgia',
  'Bosna i Hercegovina': 'Bośnia i Hercegowina',
  'Bugarska': 'Bułgaria',
  'Hrvatska': 'Chorwacja',
  'Cipar': 'Cypr',
  'Češka': 'Czechy',
  'Danska': 'Dania',
  'Estonija': 'Estonia',
  'Finska': 'Finlandia',
  'Francuska': 'Francja',
  'Gruzija': 'Gruzja',
  'Njemačka': 'Niemcy',
  'Grčka': 'Grecja',
  'Mađarska': 'Węgry',
  'Island': 'Islandia',
  'Irska': 'Irlandia',
  'Italija': 'Włochy',
  'Kazahstan': 'Kazachstan',
  'Latvija': 'Łotwa',
  'Lihtenštajn': 'Lichtenstein',
  'Litva': 'Litwa',
  'Luksemburg': 'Luxemburg',
  'Sjeverna Makedonija': 'Macedonia',
  'Malta': 'Malta',
  'Moldavija': 'Mołdawia',
  'Monako': 'Monako',
  'Crna Gora': 'Czarnogóra',
  'Nizozemska': 'Holandia',
  'Norveška': 'Norwegia',
  'Poljska': 'Polska',
  'Portugal': 'Portugalia',
  'Rumunjska': 'Rumunia',
  'Rusija': 'Rosja',
  'San Marino': 'San Marino',
  'Srbija': 'Serbia',
  'Slovačka': 'Słowacja',
  'Španjolska': 'Hiszpania',
  'Švedska': 'Szwecja',
  'Švicarska': 'Szwajcaria',
  'Turska': 'Turcja',
  'Ukrajina': 'Ukraina',
  'Velika Britanija': 'Wielka Brytania',
  'Vatikan': 'Watykan',
};

export const CzechToPolishCountry = {
  'Albánie': 'Albania',
  'Andorra': 'Andora',
  'Arménie': 'Armenia',
  'Rakousko': 'Austria',
  'Ázerbajdžán': 'Azerbejdżan',
  'Bělorusko': 'Białoruś',
  'Belgie': 'Belgia',
  'Bosna a Hercegovina': 'Bośnia i Hercegowina',
  'Bulharsko': 'Bułgaria',
  'Chorvatsko': 'Chorwacja',
  'Kypr': 'Cypr',
  'Česká republika': 'Czechy',
  'Dánsko': 'Dania',
  'Estónsko': 'Estonia',
  'Finsko': 'Finlandia',
  'Francie': 'Francja',
  'Gruzie': 'Gruzja',
  'Německo': 'Niemcy',
  'Řecko': 'Grecja',
  'Maďarsko': 'Węgry',
  'Island': 'Islandia',
  'Irsko': 'Irlandia',
  'Itálie': 'Włochy',
  'Kazachstán': 'Kazachstan',
  'Lotyšsko': 'Łotwa',
  'Lichtenštejnsko': 'Lichtenstein',
  'Litva': 'Litwa',
  'Lucembursko': 'Luxemburg',
  'Makedonie': 'Macedonia',
  'Malta': 'Malta',
  'Moldavsko': 'Mołdawia',
  'Monako': 'Monako',
  'Černá Hora': 'Czarnogóra',
  'Holandsko': 'Holandia',
  'Norsko': 'Norwegia',
  'Polsko': 'Polska',
  'Portugalsko': 'Portugalia',
  'Rumunsko': 'Rumunia',
  'Rusko': 'Rosja',
  'San Marino': 'San Marino',
  'Srbsko': 'Serbia',
  'Slovensko': 'Słowacja',
  'Španělsko': 'Hiszpania',
  'Švédsko': 'Szwecja',
  'Švýcarsko': 'Szwajcaria',
  'Turecko': 'Turcja',
  'Ukrajina': 'Ukraina',
  'Velká Británie': 'Wielka Brytania',
  'Vatikán': 'Watykan',
};


export const CountriesDictionaries = {
  'en': EnglishToPolishCountry,
  'de': GermanToPolishCountry,
  'es': SpainToPolishCountry,
  'hr': CroatiaToPolishCountry,
  'cz': CzechToPolishCountry,
  'fr': FranceToPolishCountry,
}
