import React, { RefObject, useEffect, useRef } from 'react';

import UploadFile from '@assets/svg/upload-file.svg';

import s from './FileUploader.module.scss';

interface IFileUploader {
  convertFile(): void;

  selectedFileName: string | null;
  reference: RefObject<HTMLInputElement>;
  placeholder: string;
  isFileWrong: boolean;
}

export const FileUploader = ({
                               convertFile,
                               selectedFileName,
                               reference,
                               placeholder,
                               isFileWrong,
                             }: IFileUploader) => {
  const refFileUploaderWrapper = useRef<HTMLDivElement>(null);

  const serviceElementFocus = (borderColor: string) => {
    if (refFileUploaderWrapper.current) {
      refFileUploaderWrapper.current.style.borderColor = borderColor;
    }
  };

  useEffect(() => {
    const addFocusToElement = () => serviceElementFocus('black');
    const addFocusOutToElement = () => serviceElementFocus('white');

    reference.current?.addEventListener('focus', addFocusToElement);
    reference.current?.addEventListener('blur', addFocusOutToElement);

    return () => {
      reference.current?.removeEventListener('focus', addFocusToElement);
      reference.current?.removeEventListener('blur', addFocusOutToElement);
    };
  }, [reference.current]);

  return (
    <div className={s.fileUploader} ref={refFileUploaderWrapper}>
      <label className={s.fileUploader__label} htmlFor="file">
        <input ref={reference} className={s.fileUploader__input} onChange={() => convertFile()} name="receiptPhoto"
               type="file"
               id="file"
               required
               accept="image/png, image/jpeg, image/jpg, application/pdf"
        />
        <p
          className={`${s.fileUploader__labelName} ${selectedFileName ? s.full : ''} ${isFileWrong ? s.red : ''}`}>{placeholder}</p>
        <label className={`${s.fileUploader__name} ${selectedFileName ? s.full : ''} ${isFileWrong ? s.red : ''}`}
               htmlFor="file">{selectedFileName ? selectedFileName : placeholder}</label>
        <div className={`${s.fileUploader__icon} ${selectedFileName ? s.selected : ''}`}>
          <UploadFile className={s.fileUploader__iconSvg} />
        </div>
      </label>
    </div>
  );
};
