import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import { useIntl } from 'gatsby-plugin-intl';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import DatePicker from 'react-date-picker/dist/entry.nostyle';

import { Button } from '../button/Button';
import { Input } from '../input/Input.component';
import { CustomSelect } from '../custom-select/CustomSelect.component';
import { FileUploader } from '../file-uploader/FileUploader.component';
import TypingListHelper from '@components/typing-list-helper/TypingListHelper.component';


import {
  getBase64,
  renderCheckbox,
  open,
  hide,
  closeAllDropdowns,
  rollFileName,
  handleFormSubmit, renderRepeatableField, renderQuestionField, addAnotherProduct, AcceptableFileFormats,
} from './GuaranteeForm.helper';

import Calendar from '@assets/svg/calendar.svg';
import WR_CZ from '../../../static/warrantyRegulations_CZ.pdf';
import WR_DE from '../../../static/warrantyRegulations_DE.pdf';
import WR_EN from '../../../static/warrantyRegulations_EN.pdf';
import WR_ES from '../../../static/warrantyRegulations_ES.pdf';
import WR_FR from '../../../static/warrantyRegulations_FR.pdf';
import WR_PL from '../../../static/warrantyRegulations_PL.pdf';
import WR_HR from '../../../static/warrantyRegulations_HR.pdf';

const StaticFiles = {
  'pl': WR_PL,
  'de': WR_DE,
  'en': WR_EN,
  'es': WR_ES,
  'fr': WR_FR,
  'cz': WR_CZ,
  'hr': WR_HR,
};

import { Loader } from '../loader/Loader.component';
import s from './GuaranteeForm.module.scss';

interface IGuaranteeForm {
  header: string
  subtitle: string
  namePlaceholder: string
  emailPlaceholder: string
  phonePlaceholder: string
  serialNumberPlaceholder: string
  dateOfPurchasePlaceholder: string
  productPlaceholder: string
  childBirthDatePlaceholder: string
  datepickerLabel: string
  countryNameLabel: string
  codeOfResidencePlaceholder: string
  shopNamePlaceholder: string
  shopPlaceNamePlaceholder: string
  checkboxStationaryShopping: string
  checkboxLabelPart1: string,
  checkboxLabelPart2: string,
  checkboxLabelTwo: string,
  checkboxLabelThree: string,
  checkboxLabelFour: string,
  checkboxLabelFive: string,
  checkboxLabelSix: string,
  onlineShoppingLabel: string,
  guaranteeProductList: string[],
  dateRange: string[],
  outsideRef: RefObject<HTMLInputElement>
  currentProduct?: string,
}

export const GuaranteeForm: FC<IGuaranteeForm> = (props) => {
  const {
    header,
    subtitle,
    namePlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    serialNumberPlaceholder,
    dateOfPurchasePlaceholder,
    productPlaceholder,
    childBirthDatePlaceholder,
    datepickerLabel,
    countryNameLabel,
    codeOfResidencePlaceholder,
    shopNamePlaceholder,
    shopPlaceNamePlaceholder,
    checkboxStationaryShopping,
    checkboxLabelPart1,
    checkboxLabelPart2,
    checkboxLabelTwo,
    checkboxLabelThree,
    checkboxLabelFour,
    checkboxLabelFive,
    checkboxLabelSix,
    onlineShoppingLabel,
    guaranteeProductList,
    dateRange,
    outsideRef,
    currentProduct
  } = props;

  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();

  const checboxLabels = [
    checkboxLabelTwo,
    checkboxLabelThree,
    checkboxLabelFour,
    checkboxLabelFive,
    checkboxLabelSix,
  ];

  const questionHowDidYouFindAnswers = [
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a1' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a2' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a3' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a4' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a5' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a6' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a7' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a8' }),
    intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a9' }),
  ]

  const questionWhatPromptedYouAnswers = [
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a1' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a2' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a3' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a4' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a5' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a6' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a7' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a8' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a9' }),
    intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a10' }),
  ]

  const europeanCountry = [
    intl.formatMessage({ id: 'ecAlbania' }),
    intl.formatMessage({ id: 'ecAndorra' }),
    intl.formatMessage({ id: 'ecArmenia' }),
    intl.formatMessage({ id: 'ecAustria' }),
    intl.formatMessage({ id: 'ecAzerbaijan' }),
    intl.formatMessage({ id: 'ecBelarus' }),
    intl.formatMessage({ id: 'ecBelgium' }),
    intl.formatMessage({ id: 'ecBosnia' }),
    intl.formatMessage({ id: 'ecBulgaria' }),
    intl.formatMessage({ id: 'ecCroatia' }),
    intl.formatMessage({ id: 'ecCyprus' }),
    intl.formatMessage({ id: 'ecCzech' }),
    intl.formatMessage({ id: 'ecDenmark' }),
    intl.formatMessage({ id: 'ecEstonia' }),
    intl.formatMessage({ id: 'ecFinland' }),
    intl.formatMessage({ id: 'ecFrance' }),
    intl.formatMessage({ id: 'ecGeorgia' }),
    intl.formatMessage({ id: 'ecGermany' }),
    intl.formatMessage({ id: 'ecGreece' }),
    intl.formatMessage({ id: 'ecHungary' }),
    intl.formatMessage({ id: 'ecIceland' }),
    intl.formatMessage({ id: 'ecIreland' }),
    intl.formatMessage({ id: 'ecItaly' }),
    intl.formatMessage({ id: 'ecKazakhstan' }),
    intl.formatMessage({ id: 'ecLatvia' }),
    intl.formatMessage({ id: 'ecLiechtenstein' }),
    intl.formatMessage({ id: 'ecLithuania' }),
    intl.formatMessage({ id: 'ecLuxembourg' }),
    intl.formatMessage({ id: 'ecMacedonia' }),
    intl.formatMessage({ id: 'ecMalta' }),
    intl.formatMessage({ id: 'ecMoldova' }),
    intl.formatMessage({ id: 'ecMonaco' }),
    intl.formatMessage({ id: 'ecMontenegro' }),
    intl.formatMessage({ id: 'ecNetherlands' }),
    intl.formatMessage({ id: 'ecNorway' }),
    intl.formatMessage({ id: 'ecPoland' }),
    intl.formatMessage({ id: 'ecPortugal' }),
    intl.formatMessage({ id: 'ecRomania' }),
    intl.formatMessage({ id: 'ecRussia' }),
    intl.formatMessage({ id: 'ecSanMarino' }),
    intl.formatMessage({ id: 'ecSerbia' }),
    intl.formatMessage({ id: 'ecSlovakia' }),
    intl.formatMessage({ id: 'ecSpain' }),
    intl.formatMessage({ id: 'ecSweden' }),
    intl.formatMessage({ id: 'ecSwitzerland' }),
    intl.formatMessage({ id: 'ecTurkey' }),
    intl.formatMessage({ id: 'ecUkraine' }),
    intl.formatMessage({ id: 'ecUK' }),
  ]


  const [selectedGDPR, setCheckboxSelectedGDPR] = useState<boolean>(false);
  const [selectedShoppingStationary, setCheckboxSelectedShoppingStationary] = useState<boolean>(false);
  const [selected, setCheckboxSelected] = useState<boolean[]>([false, false, false, false, false]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>(currentProduct ? [currentProduct] :[]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<string[]>([]);
  const [selectedAnswerToTheQuestionHowDidYouFind, setSelectedAnswerToTheQuestionHowDidYouFind] = useState<string>();
  const [selectedOtherToTheQuestionHowDidYouFind, setSelectedOtherToTheQuestionHowDidYouFind] = useState<boolean>(false);
  const [typedOtherToTheQuestionHowDidYouFind, setTypedOtherToTheQuestionHowDidYouFind] = useState<string>();
  const [selectedAnswerToTheQuestionWhatPromptedYou, setSelectedAnswerToTheQuestionWhatPromptedYou] = useState<string>();
  const [selectedOtherToTheQuestionWhatPromptedYou, setSelectedOtherToTheQuestionWhatPromptedYou] = useState<boolean>(false);
  const [typedOtherToTheQuestionWhatPromptedYou, setTypedOtherToTheQuestionWhatPromptedYou] = useState<string>();
  const [typedCountryName, setTypedCountryName] = useState<string>('');
  const [selectedCountryName, setSelectedCountryName] = useState<string>('');
  const [IsSelectedCountryNameWrong, setIsSelectedCountryNameWrong] = useState<boolean>(false);
  const [selectedDateOfPurchase, setSelectedDateOfPurchase] = useState<string>('');
  const [displayDropdownProducts, setDisplayDropdownProducts] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false]);
  const [displayDropdownDateOfPurchase, setDisplayDropdownDateOfPurchase] = useState<boolean>(false);
  const [displayDropdownCountryName, setDisplayDropdownCountryName] = useState<boolean>(false);
  const [selectedChildBirthDate, setSelectedChildBirthDate] = useState<Date | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<string>('');
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [selectedFileIsTooBig, setselectedFileIsTooBig] = useState<boolean>(false);
  const [selectedFileHasWrongFormat, setSelectedFileHasWrongFormat] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShopPlaceNameVisible, setIsShopPlaceNameVisible] = useState<boolean>(false);
  const [isTooMuchAddedProducts, setIsTooMuchAddedProducts] = useState<boolean>(false);
  const [renderedProductsNumber, setRenderedProductsNumber] = useState<number>(0);
  const [stateCountry, setstateCountry] = useState(europeanCountry)
  const [shopName, setShopName] = useState<string | null>(null);
  const [shopPlaceName, setShopPlaceName] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [codeOfResidence, setCodeOfResidence] = useState<string | null>(null);
  
  const refFile = useRef<HTMLInputElement>(null);
  const refShopPlaceName = useRef<HTMLDivElement>(null);
  const refSn1 = useRef<HTMLInputElement>(null);
  const refSn2 = useRef<HTMLInputElement>(null);
  const refSn3 = useRef<HTMLInputElement>(null);
  const refSn4 = useRef<HTMLInputElement>(null);
  const refSn5 = useRef<HTMLInputElement>(null);
  const refSn6 = useRef<HTMLInputElement>(null);
  const refSn7 = useRef<HTMLInputElement>(null);
  const refSn8 = useRef<HTMLInputElement>(null);

  const snRefs = [
    refSn1,
    refSn2,
    refSn3,
    refSn4,
    refSn5,
    refSn6,
    refSn7,
    refSn8,
  ];
  
  const getCorrectPlaceholder = () => {
    if(selectedFileIsTooBig) {
      return intl.formatMessage({ id: 'guarantee__fileIsTooBig' })
    }else if(selectedFileHasWrongFormat) {
      return <>{intl.formatMessage({ id: 'guarantee__fileHasWrongFormatPart1' })} <br /> {intl.formatMessage({ id: 'guarantee__fileHasWrongFormatPart2' })} </>
    }
    else {
      return intl.formatMessage({ id: 'guarantee__fileUploader' })
    }
  };

  const setProduct = (product, index) => {
    const newState = [...selectedProducts];

    newState[index] = product;

    setSelectedProducts(newState);
    handleSetDisplayDropdownProducts(false, index);
  };

  const handleSetSerialNumber = (sn, index, reference) => {
    const newState = [...selectedSerialNumbers];

    if (newState.indexOf(sn) >= 0) {
      reference.current.setCustomValidity(intl.formatMessage({ id: 'guarantee__formUniqueSn' }));
    } else {
      reference.current.setCustomValidity('');
      newState[index] = sn;

      setSelectedSerialNumbers(newState);
    }
  };
  
  const handleAnswerToTheQuestionHowDidYouFind = (value: string) => {
    if(value === intl.formatMessage({ id: 'warranty__questionHowDidYouFind_a9' })) {
      setSelectedOtherToTheQuestionHowDidYouFind(true);
      setSelectedAnswerToTheQuestionHowDidYouFind('')
    }
    else {
      setSelectedOtherToTheQuestionHowDidYouFind(false);
      setTypedOtherToTheQuestionHowDidYouFind('')
      setSelectedAnswerToTheQuestionHowDidYouFind(value)
    }
  }

  const handleAnswerToTheQuestionWhatPromptedYouAnswer = (value: string) => {
    if(value === intl.formatMessage({ id: 'warranty__questionWhatPromptedYou_a10' })) {
      setSelectedOtherToTheQuestionWhatPromptedYou(true);
      setSelectedAnswerToTheQuestionWhatPromptedYou('')
    }
    else {
      setSelectedOtherToTheQuestionWhatPromptedYou(false);
      setTypedOtherToTheQuestionWhatPromptedYou('')
      setSelectedAnswerToTheQuestionWhatPromptedYou(value)
    }
  }

  const handleSetDisplayDropdownProducts = (visibility, index) => {
    const newState = [...displayDropdownProducts];

    newState[index] = visibility;

    setDisplayDropdownProducts(newState);
  };

  const setCountryName = (countryName: string) => {
    setSelectedCountryName(countryName);
    setTypedCountryName(countryName);
    setDisplayDropdownCountryName(false);
    setIsSelectedCountryNameWrong(false);
  };

  const setDateOfPurchase = (date) => {
    setSelectedDateOfPurchase(date);
    setDisplayDropdownDateOfPurchase(false);
  };

  const convertFile = () => {
    const byteLimit = 2000000;
    const selectedFile = refFile.current.files[0];

    if (selectedFile && selectedFile.size <= byteLimit) {

      getBase64(selectedFile).then((base64) => {
        if (typeof base64 === 'string') {
          
          const matches = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
          const fileFormat: string | null = matches && matches[1];

          if (fileFormat && AcceptableFileFormats.includes(fileFormat)) {
            refFile.current.setCustomValidity('');
            setSelectedPhoto(base64);
            setSelectedFileName(rollFileName(selectedFile.name));
            setSelectedFileHasWrongFormat(false);
          } else {
            refFile.current.setCustomValidity(`${intl.formatMessage({ id: 'guarantee__fileHasWrongFormatPart1' })} ${intl.formatMessage({ id: 'guarantee__fileHasWrongFormatPart2' })}`);
            setSelectedPhoto('');
            setSelectedFileName('');
            setSelectedFileHasWrongFormat(true);
          }

          setselectedFileIsTooBig(false);
        }
      });
    } else {
      refFile.current.setCustomValidity(intl.formatMessage({ id: 'guarantee__fileIsTooBig' }));
      setSelectedPhoto('');
      setSelectedFileName('');
      setselectedFileIsTooBig(true);
      sendDataToGTM({
        'event': 'warranty-form',
        'event_category': 'scan of the receipt',
        'event_label': 'file is too big',
        'event_value': selectedFile.size,
        'event_language': intl.locale,
      })
    }
  };

  const handleChangeCountry = (e) => {
    const results = europeanCountry.filter(country => {
      if (e.target.value === '') return europeanCountry
      return country.toLowerCase().startsWith(e.target.value.toLowerCase())
    })

    setstateCountry(results)

    if(results.length === 1) {
      if(selectedCountryName.length === 0) {
        setSelectedCountryName(results[0])
        setTypedCountryName(results[0]);
        setIsSelectedCountryNameWrong(false)
      }
      else {
        setIsSelectedCountryNameWrong(false)
        setTypedCountryName(e.target.value);
      }
    }
    else if (results.length === 0) {
      handleSetIsSelectedCountryNameWrong();
    }
    else {
      setIsSelectedCountryNameWrong(false)
      setSelectedCountryName('')
      setTypedCountryName(e.target.value);
    }
  }
  
  const serviceElementFocus = (borderColor:string) => {
    const datePickerElement = document.getElementsByClassName(s.guaranteeForm__datePicker)[0];

    if (datePickerElement) {
      datePickerElement.style.borderColor = borderColor;
    }
  };
  
  const handleSetIsSelectedCountryNameWrong = () => {
    setTypedCountryName('');
    setSelectedCountryName('')
    setIsSelectedCountryNameWrong(true)
  }

  useEffect(() => {
    const tl = gsap.timeline();
    if (refShopPlaceName.current !== null) {
      tl.set(refShopPlaceName.current, {
        autoAlpha: 0,
        height: 0,
      });
    }
  }, [refShopPlaceName.current]);

  const handleCloseAllDropdowns = () => {
    closeAllDropdowns(setDisplayDropdownProducts, setDisplayDropdownDateOfPurchase, setDisplayDropdownCountryName);
  };

  useEffect(() => {
    outsideRef.current?.addEventListener('click', handleCloseAllDropdowns, false);
    return () => {
      outsideRef.current?.removeEventListener('click', handleCloseAllDropdowns, false);
    };
  }, [outsideRef.current]);

  useEffect(() => {
    if (typeof document !== 'undefined') {

      const addFocusToElement = () => serviceElementFocus('black');
      const addFocusOutToElement = () => serviceElementFocus('white');
      
      document.getElementsByClassName('react-date-picker__inputGroup__input')[0]?.addEventListener('focus', addFocusToElement);
      document.getElementsByClassName('react-date-picker__inputGroup__input')[0]?.addEventListener('blur', addFocusOutToElement);

      return () => {
        document.getElementsByClassName('react-date-picker__inputGroup__input')[0]?.removeEventListener('focus', addFocusToElement);
        document.getElementsByClassName('react-date-picker__inputGroup__input')[0]?.removeEventListener('blur', addFocusOutToElement);
      };
    }
  });

  return (
    <div className={s.guaranteeForm}>
      <Loader isLoading={isLoading}/>
      <div className={s.guaranteeForm__header}>
        <h1 className={s.guaranteeForm__headerText}>
          {header}
        </h1>
        <p className={s.guaranteeForm__subtitleText}>
          {subtitle}
        </p>
      </div>
      <form
        className={s.guaranteeForm__body}
        onSubmit={() => handleFormSubmit(event, setIsLoading, selectedFileIsTooBig, selectedFileHasWrongFormat, selectedCountryName, selectedDateOfPurchase, selectedProducts, selectedChildBirthDate, selectedPhoto, isShopPlaceNameVisible, intl.locale, onlineShoppingLabel, selected, handleSetIsSelectedCountryNameWrong, selectedAnswerToTheQuestionHowDidYouFind, selectedAnswerToTheQuestionWhatPromptedYou)}
        id={'guarantee-form'}
        autoComplete="off"
      >
        <div className={s.guaranteeForm__repeatField}>
          {renderRepeatableField(snRefs, selectedSerialNumbers, handleSetSerialNumber, setSelectedSerialNumbers, renderedProductsNumber, productPlaceholder, guaranteeProductList, displayDropdownProducts, handleSetDisplayDropdownProducts, selectedProducts, setProduct, serialNumberPlaceholder, setSelectedProducts, setRenderedProductsNumber, setIsTooMuchAddedProducts, intl.formatMessage({ id: 'guarantee__tooltip' }))}
          <div className={s.guaranteeForm__repeatButton}>
            <span
              className={`${s.guaranteeForm__repeatText} ${isTooMuchAddedProducts ? s.disabled: '' }`}
              onClick={() => addAnotherProduct(renderedProductsNumber, setRenderedProductsNumber, setIsTooMuchAddedProducts)}
            >
              {intl.formatMessage({ id: 'guarantee__formRepeatField' })}
            </span>
          </div>
        </div>
        <div className={s.guaranteeForm__field}>
          {renderQuestionField(handleAnswerToTheQuestionHowDidYouFind, selectedAnswerToTheQuestionHowDidYouFind, selectedOtherToTheQuestionHowDidYouFind, setTypedOtherToTheQuestionHowDidYouFind, typedOtherToTheQuestionHowDidYouFind, intl.formatMessage({ id: 'warranty__questionHowDidYouFind' }), intl.formatMessage({ id: 'warranty__typeAnswer' }), questionHowDidYouFindAnswers, handleSetDisplayDropdownProducts, displayDropdownProducts, 9, 'howDidYouFind')}
        </div>
        <div className={s.guaranteeForm__field}>
          {renderQuestionField(handleAnswerToTheQuestionWhatPromptedYouAnswer, selectedAnswerToTheQuestionWhatPromptedYou, selectedOtherToTheQuestionWhatPromptedYou, setTypedOtherToTheQuestionWhatPromptedYou, typedOtherToTheQuestionWhatPromptedYou, intl.formatMessage({ id: 'warranty__questionWhatPromptedYou' }), intl.formatMessage({ id: 'warranty__typeAnswer' }), questionWhatPromptedYouAnswers, handleSetDisplayDropdownProducts, displayDropdownProducts, 10, 'whatPromptedYou' )}
        </div>
        <div className={s.guaranteeForm__field}>
          <TypingListHelper
            isValueWrong={IsSelectedCountryNameWrong}
            visible={displayDropdownCountryName}
            handleClick={setDisplayDropdownCountryName}
            handleSelect={setCountryName}
            handleChangeCountry={handleChangeCountry}
            range={stateCountry} 
            value={typedCountryName} 
            placeholder={countryNameLabel}
            required
          />
        </div>
        <Input placeholder={shopNamePlaceholder} name={'shop-name'} isFull={shopName} type={'text'} value={shopName}
               changeMethod={setShopName} required/>
        <div className={s.guaranteeForm__checkbox}
             onClick={isShopPlaceNameVisible
               ? () => hide(setCheckboxSelectedShoppingStationary, refShopPlaceName, setIsShopPlaceNameVisible)
               : () => open(setCheckboxSelectedShoppingStationary, refShopPlaceName, setIsShopPlaceNameVisible)}
        >
          <input
            className={`${s.guaranteeForm__checkboxInput} ${ selectedShoppingStationary ? s.selected: '' }`}
            type="checkbox"
            checked={selectedShoppingStationary}
            onChange={() => {
            }}
          />
          <label className={s.guaranteeForm__checkboxLabel}>
            <p>{checkboxStationaryShopping}</p>
          </label>
        </div>
        <div className={s.guaranteeForm__animateField} ref={refShopPlaceName}>
          <Input
            placeholder={shopPlaceNamePlaceholder}
            name={'shop-place-name'}
            isFull={shopPlaceName}
            type={'text'}
            value={shopPlaceName}
            changeMethod={setShopPlaceName}
            required={isShopPlaceNameVisible}
          />
        </div>
        <div className={s.guaranteeForm__field}>
          <CustomSelect
            placeholder={dateOfPurchasePlaceholder}
            range={dateRange}
            visible={displayDropdownDateOfPurchase}
            handleClick={setDisplayDropdownDateOfPurchase}
            value={selectedDateOfPurchase}
            handleSelect={setDateOfPurchase}
            isGuaranteeForm
            required
          />
        </div>
        <div className={s.guaranteeForm__field}>
          <FileUploader
            convertFile={convertFile}
            selectedFileName={selectedFileName}
            reference={refFile}
            placeholder={getCorrectPlaceholder()}
            isFileWrong={selectedFileIsTooBig || selectedFileHasWrongFormat}
          />
        </div>
        <Input
          placeholder={namePlaceholder}
          name={'name'}
          isFull={name}
          value={name}
          changeMethod={setName}

          type={'text'}
          required/>
        <Input
          placeholder={phonePlaceholder}
          name={'phone'}
          isFull={phone}
          value={phone}
          changeMethod={setPhone}
          type={'text'}
        />
        <Input
          placeholder={emailPlaceholder}
          name={'email'}
          isFull={email}
          value={email}
          changeMethod={setEmail}
          type={'email'}
          required
        />
        <Input
          placeholder={codeOfResidencePlaceholder}
          name={'code-of-residence'}
          isFull={codeOfResidence}
          value={codeOfResidence}
          changeMethod={setCodeOfResidence}
          type={'text'}
          required
        />

        <div className={s.guaranteeForm__field}>
          <DatePicker
            onChange={setSelectedChildBirthDate}
            value={selectedChildBirthDate}
            className={s.guaranteeForm__datePicker}
            format={'yyyy-MM-dd'}
            yearPlaceholder={childBirthDatePlaceholder}
            monthPlaceholder={''}
            dayPlaceholder={''}
            locale={intl.locale}
            clearIcon={null}
            calendarIcon={
              <div className={`${s.guaranteeForm__icon} ${selectedChildBirthDate ? s.selected: '' }`}>
                <Calendar className={s.guaranteeForm__iconSvg}/>
              </div>
            }
            required
          />
          <p
            className={`${s.guaranteeForm__label} ${ selectedChildBirthDate !== null ? s.full : ''}`}
          >
            {childBirthDatePlaceholder}
          </p>
          <p className={s.guaranteeForm__datePickerLabel}>{datepickerLabel}</p>
        </div>
        <div className={s.guaranteeForm__checkbox}>
            <p className={s.guaranteeForm__correctInfo}>{intl.formatMessage({ id: 'warranty__correctInfo' })}</p>
        </div>
        <div className={s.guaranteeForm__checkbox} onClick={() => setCheckboxSelectedGDPR(!selectedGDPR)}>
          <input
            className={`${s.guaranteeForm__checkboxInput} ${ selectedGDPR ? s.selected :'' }`}
            type="checkbox"
            required
            checked={selectedGDPR}
            onChange={() => {
            }}/>
          <label className={s.guaranteeForm__checkboxLabel}>
            <p>
              {checkboxLabelPart1}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={s.guaranteeForm__link}
                href={StaticFiles[intl.locale]}
                hrefLang={intl.locale}
                aria-label={checkboxLabelPart2}
              >
                {checkboxLabelPart2}
              </a>
            </p>
          </label>
        </div>
        {renderCheckbox(selected, checboxLabels, setCheckboxSelected)}
        <div className={s.guaranteeForm__button}>
          <Button>{intl.formatMessage({ id: 'guarantee__formSubmit' })}</Button>
        </div>
      </form>
    </div>
  );
};
